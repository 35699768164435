import fetch from 'auth/FetchInterceptor'
import {API_BASE_URL} from '../configs/AppConfig'
const CaregiverService = {}


CaregiverService.getCaregiver = function (params) {
	 
	 let offset = (params-1)*10;
	return fetch({
		url: API_BASE_URL+'/v1/admin/get-care-giver',
		method: 'get',
        params: {
            'offset' : offset
        }
	})
};


CaregiverService.getCaregiverParticipants = function (data) {
	 let {id, page} = data;
	let offset = (page-1)*10
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-care-giver-participant/${id}`,
		method: 'get',
		params: {
            'offset': offset
        },

	})
};

CaregiverService.getCaregiverGuestParticipants = function (data) {
	let {id, page} = data;
   let offset = (page-1)*10
   return fetch({
	   url: API_BASE_URL+`/v1/admin/get-care-giver-guest-participant/${id}`,
	   method: 'get',
	   params: {
		   'offset': offset
	   },

   })
};

CaregiverService.deleteCaregiver = function (id) {
	return fetch({
		url: API_BASE_URL+`/v1/admin/delete-user/${id}`,
		method: 'delete',

	})
};

CaregiverService.getCaregiverWithId = function (id) {
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-care-giver-details/${id}`,
		method: 'get',

	})
};

CaregiverService.getParticipantWithId = function (id) {
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-participant-details/${id}`,
		method: 'get',

	})
};

CaregiverService.getCaregiverProgram = function (params) {
	let {id, filter, page} = params
	let offset = (page-1)*10
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-caregiver-programs/${id}`,
		method: 'get',
		params: {
            'offset': offset,
			'filter': filter
        },

	})
};

CaregiverService.getParticipantProgram = function (params) {
	let {id, filter, page} = params
	let offset = (page-1)*10
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-participants-programs/${id}`,
		method: 'get',
		params: {
            'offset': offset,
			'filter': filter
        },

	})
};



export default CaregiverService;