import fetch from 'auth/FetchInterceptor'
import {API_BASE_URL} from '../configs/AppConfig'
const InstructorService = {}


InstructorService.getInstructor = function (params) {
	let offset = (params-1)*10
	return fetch({
		url: API_BASE_URL+'/v1/admin/get-instructor',
		method: 'get',
        params: {
            'offset' : offset
        }
	})
};


InstructorService.deleteInstructor = function (id) {
	return fetch({
		url: API_BASE_URL+`/v1/admin/delete-instructor/${id}`,
		method: 'delete',

	})
};

InstructorService.registerInstructor = function (data) {
	return fetch({
		url: API_BASE_URL+`/v1/admin/register-instructor`,
		method: 'post',
		data: data
	})
};

InstructorService.editInstructor = function (allData) {
	let {id, data} = allData;
	return fetch({
		url: API_BASE_URL+`/v1/admin/edit-instructor/${id}`,
		method: 'put',
		data
	})
};

InstructorService.getSingleInstructor = function (id) {
	//let offset = (params-1)*10
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-single-instructor/${id}`,
		method: 'get',
       
	})
};



export default InstructorService;